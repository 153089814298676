/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

.gwc-dialog-header {
    @apply font-medium text-lg bg-gray-100 p-3 ;
    margin-top: -24px !important;
    margin-left: -24px !important;
    margin-right: -24px !important;
    
}



